.app {
  .container {
    .managePasswords {
      #manage-passwords-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 40px);
        height: 100%;
        padding: 20px;

        button {
          width: 100px;
          height: 30px;
          border: none;
          border-radius: 5px;
        }

        button:hover {
          background-color: grey;
        }

        #table {
          margin-top: 10px;
          font-size: 18px;
          border-collapse: collapse;
          width: 100%;
          table-layout: fixed;

          thead {
            background-color: #FB6100;

            tr {
              th {
                padding: 5px;
                border: 1px solid black;
              }
            }
          }

          tbody {
            background-color: lightgrey;

            tr {
              td {
                padding: 5px;
                height: 40px;
                font-size: 15px;
                border: 1px solid black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .button-container {
                padding: 5px;
                height: 40px;
                font-size: 15px;
                border: 1px solid black;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                button {
                  margin-left: 10px;
                  padding: 0px 5px;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}