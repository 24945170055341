.app {
  .container {
    width: 100%;
    .showContent {
      #showContent-container {
        background-color: lightgrey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        #failed-con {
          width: 50%;
          height: 50%;
          background-color: #FB6100;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 25px;
          gap: 10px;
        }

        #content {
          width: 50%;
          height: 50%;
          min-width: 550px;
          min-height: 200px;
          background-color: #FB6100;
          border-radius: 15px;
          padding: 10px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          .div1 { grid-area: 1 / 1 / 2 / 2; }
          .div2 { grid-area: 4 / 1 / 5 / 2; }
          .div3 { grid-area: 2 / 1 / 4 / 2; }

          .div1{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            text-decoration: underline;
            font-weight: bold;
          }

          .div2{
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            justify-content: center;
            
            #warning{
              font-size: 20px;
            }

            #firma{
              font-size: 16px;
            }

          }

          .div3{
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            justify-content: center;
            position: relative;

            #content-output{
              width: calc(100% - 20px);
              height: 100%;
              border-radius: 5px;
              padding: 10px;
              font-size: 16px;
              border: none;
            }

          }
        }

        #succ-con {
          width: 50%;
          height: 50%;
          min-width: 550px;
          min-height: 200px;
          background-color: #FB6100;
          border-radius: 15px;
          padding: 10px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          .div1 { grid-area: 1 / 1 / 2 / 2; }
          .div2 { grid-area: 4 / 1 / 5 / 2; }
          .div3 { grid-area: 2 / 1 / 4 / 2; }

          .div1{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 26px;
            text-decoration: underline;
            font-weight: bold;
          }

          .div2{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
          }

          .div3{
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;
            justify-content: center;
            font-size: 22px;

            input{
              width: 300px;
              height: 30px;
              border: none;
              border-radius: 5px;
              padding: 5px;
              font-size: 20px;
            }

            button{
              width: 210px;
              height: 40px;
              border: none;
              border-radius: 5px;
              font-size: 16px;
              cursor: pointer;
            }

            button:hover{
              background-color: grey;
            }
          }
        }

        #loading {
          width: 500px;
          height: 500px;
          background-color: #FB6100;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          font-size: 20px;
          align-items: center;
          justify-content: center;

          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }

            to {
              transform: rotate(360deg);
            }
          }


          @-webkit-keyframes rotate {
            from {
              -webkit-transform: rotate(0deg);
            }

            to {
              -webkit-transform: rotate(360deg);
            }
          }

          .load {
            width: 100px;
            height: 100px;
            margin-top: 50px;
            border: solid 10px grey;
            border-radius: 50%;
            border-right-color: transparent;
            border-bottom-color: transparent;
            -webkit-transition: all 0.5s ease-in;
            -webkit-animation-name: rotate;
            -webkit-animation-duration: 1.0s;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;

            transition: all 0.5s ease-in;
            animation-name: rotate;
            animation-duration: 1.0s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }

          span {
            margin-top: 20px;
          }

          #firma {
            margin-top: 170px;
            font-size: 15px;
          }
        }
      }
    }
  }
}