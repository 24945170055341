* {
  margin: 0;
  padding: 0;
  font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;
  overflow: hidden;
}

.app {
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  background-color: white;

  .container {
    .header {
      width: 100vw;
      height: 87px;
      background-color: grey;
      display: flex;

      #container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100vw;

        #cont-logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          margin-left: 60px;

          img {
            height: 40px;

          }

          span {
            font-size: 13px;
          }
        }

        #header-clock {
          order: 2;
          font-size: 25px;
        }

        #header-login {
          order: 3;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          #login-con {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
          }

          input {
            height: 40px;
            width: 180px;
            padding-left: 10px;
            border: none;
            border-radius: 5px;
          }

          button {
            margin-right: 20px;
            width: 100px;
            height: 41px;
            border: none;
            border-radius: 5px;
            font-size: 18px;
          }

          #header-user {
            margin-right: 15px;
            font-size: 18px;
          }
        }

        button:hover {
          background-color: grey;
          color: orange;
        }
      }
    }

    .logoff {
      position: absolute;
      top: 88px;
      left: 360px;
      height: calc(100vh - 87px);
      width: calc(100vw - 360px);
      background-color: white;
    }

    .logon {
      position: absolute;
      top: 88px;
      left: 360px;
      height: calc(100vh - 87px);
      width: calc(100vw - 360px);
      background-color: white;
    }

    .config {
      position: absolute;
      top: 88px;
      left: 360px;
      height: calc(100vh - 87px);
      width: calc(100vw - 360px);
      background-color: white;
      overflow: scroll;
    }

    .createPassword {
      position: absolute;
      top: 88px;
      left: 360px;
      height: calc(100vh - 87px);
      width: calc(100vw - 360px);
      background-color: white;
    }

    .managePasswords {
      position: absolute;
      top: 88px;
      left: 360px;
      height: calc(100vh - 87px);
      width: calc(100vw - 360px);
      background-color: white;
    }

    .showContent {
      height: 100%;
      width: 100%;
      background-color: white;
    }
  }
}