.app {
  .container {
    .config {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      #config-header{
        font-size: 26px;
        margin: 30px 0px 30px 0px;
      }

      #config-user{
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }

      #config-domain-prefix{
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        
        #domain-prefix{
          width: 300px;
        }
      }

    }
  }
}
