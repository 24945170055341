.app {
  .container {
    .createPassword {
      #create-password-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        #headline {
          margin: 20px;
          text-decoration: underline;
          color: black;
        }

        #header-cont {
          background-color: #FB6100;
          width: 50%;
          display: flex;
          border-radius: 15px;
          flex-direction: column;
          align-items: center;
          margin: 20px;
          padding: 20px;
          gap: 15px;
          height: 300px;

          #content-input {
            width: 100%;
            height: 200px;
            resize: none;
            padding: 3px;
            overflow: auto;
          }

          #file-upload-cont {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            margin-top: 10px;

            #file-input {
              font-size: 13px;
            }
          }
        }

        .submit-cont {
          border-radius: 15px;
          background-color: grey;
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          padding: 10px;

          #generate-link {
            padding: 5px;
          }

          #link-output {
            width: 550px;
            height: 20px;
            resize: none;
            padding: 4px;
          }

          #password-to-link {
            width: 213px;
            padding: 4px;
          }

          #copie-link {
            padding: 5px;
          }

          #comment {
            width: 300px;
            padding: 4px;
          }

          #confirm {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;

            #confirm-png {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
}