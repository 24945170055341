.statistics {
  height: 100%;
  .grid-layout {
    background: none;
    height: 100%;

    .grid-item {
      background-color: lightgray;

      &.small-paper {
        height: calc(100% - 20px); // Abzug des inneren Abstands
        border-radius: 15px;
        max-width: 230px;

        #small-content {
          padding: 10px;
          margin: 5px;
          height: 86%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.mid-paper {
        height: calc(100% - 20px);
        border-radius: 15px;
        max-width: 500px;

        #mid-content {
          padding: 10px;
          margin: 5px;
          height: 86%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.large-paper {
        height: calc(100% - 20px);
        border-radius: 15px;
        max-width: 500px;

        #large-content {
          padding: 10px;
          margin: 5px;
          height: 93%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          #chart-header {
            margin-bottom: 45px;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}
