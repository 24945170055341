.app {
  .container {
    .logoff {
      #logoff-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        #logoff-logo {
          height: 200px;
          width: 300px;
        }

        #logoff-locked {
          margin-top: 40px;
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}