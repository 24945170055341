.app {
  .container {
    .logon {
      #logon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        #logon-logo {
          height: 200px;
          width: 300px;
        }

        #logon-locked {
          margin-top: 40px;
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}